import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "@aws-amplify/auth";

const useAutoSignOut = (timeout = 15 * 60 * 1000) => {
  const navigate = useNavigate();

  useEffect(() => {
    let inactivityTimer;

    // Function to reset the inactivity timer
    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(async () => {
        try {
          console.log("Inactivity detected. Signing out...");
          // Sign out the user after the timeout
          await signOut();

          // Clear session storage only if JWT token is not present
          const jwtToken = sessionStorage.getItem("jwtToken");
          if (!jwtToken) {
            console.log("JWT token is missing, clearing session storage.");
            sessionStorage.clear();
          } else {
            console.log("JWT token found, session storage retained.");
          }

          console.log("User automatically signed out due to inactivity.");
        } catch (error) {
          console.error("Error during automatic sign-out:", error);
        } finally {
          navigate("/login"); // Redirect to the login page
        }
      }, timeout);
    };

    // Listen for any user activity and reset the timer
    const handleActivity = () => {
      console.log("User activity detected. Resetting inactivity timer.");
      resetTimer();
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    // Initialize the timer
    resetTimer();

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      clearTimeout(inactivityTimer);
    };
  }, [navigate, timeout]);
};

export default useAutoSignOut;
