// src/components/Footer.jsx

import React from "react";
import "../stylings/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <p>&copy; 2024 Crazy Claims. All Rights Reserved.</p>
          <p>
            <strong>Contact Us:</strong>{" "}
            <a href="mailto:support@crazyclaims.com">support@crazyclaims.com</a> | 
            Phone: <a href="tel:773-759-1948">773-759-1948</a> | 
            Alt: <a href="tel:708-712-9510">708-712-9510</a>
          </p>
          <p>
            <strong>Follow Us:</strong>
            <a href="#" className="footer-social-link">Twitter</a> |
            <a href="#" className="footer-social-link">Facebook</a> |
            <a href="#" className="footer-social-link">LinkedIn</a>
          </p>
          <p className="disclaimer">
            <small>
              Disclaimer: Crazy Claims does not guarantee that you will receive a payout from your claim. While we ensure that your claim is properly filed and that all necessary steps are taken to pursue the payout, the final outcome depends on the specific circumstances of each claim and decisions made by the relevant parties. Our role is to facilitate the process, not to determine the outcome.
            </small>
          </p>
          <hr />
          <p>
            <strong>Need more help?</strong> Visit our <a href="/support">Support Page</a> or{" "}
            <a href="mailto:support@crazyclaims.com">Contact Us</a>.
          </p>
          <p>
            <small>
              Website developed by <a href="https://theradicalsoftware.com" target="_blank">Radical Software</a>
            </small>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
