import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import FilerDashboard from "./components/FilerDashboard";
import Dashboard from "./components/Dashboard";
import CustomerSupport from "./components/CustomerSupport";
import FAQ from "./components/FAQ";
import Login from "./components/Login";
import TermsAndConditions from "./components/TermsAndConditions"; // Import the TermsAndConditions component
import ProtectedRoute from "./components/ProtectedRoute"; // Import the ProtectedRoute component
import { signOut } from "@aws-amplify/auth"; // Importing necessary auth functionality
import useAutoSignOut from "./components/UseAutoSignOut"; // Import the auto sign-out hook
import Header from "./components/Header"; // Import the Header component
import Footer from "./components/Footer"; // Import the Footer component

function App() {
  return (
    <Router>
      <AutoSignOutWrapper />
      <HeaderWrapper /> {/* Integrate Header component to ensure consistency across pages */}
      <Routes>
        <Route path="/" element={<Home />} />
        {/* Protect the FilerDashboard route with requiredRole */}
        <Route
          path="/FilerDashboard"
          element={
            <ProtectedRoute requiredRole="admin">
              <FilerDashboard />
            </ProtectedRoute>
          }
        />
        {/* Protect the Dashboard route with requiredRole */}
        <Route
          path="/Dashboard"
          element={
            <ProtectedRoute requiredRole="customer">
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/CustomerSupport" element={<CustomerSupport />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
      <Footer /> {/* Add Footer to render on every page */}
    </Router>
  );
}

// Wrapper to automatically handle user sign-out after a period of inactivity
const AutoSignOutWrapper = () => {
  useAutoSignOut(); // This ensures the hook is only used inside the Router
  return null;
};

// Define the HeaderWrapper component to handle the header consistently
const HeaderWrapper = () => {
  // State to track if the user is logged in
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  React.useEffect(() => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    setIsLoggedIn(jwtToken && isTokenValid(jwtToken));

    // Helper function to check if the JWT token is valid
    function isTokenValid(jwtToken) {
      if (!jwtToken) return false;
      const payloadBase64 = jwtToken.split(".")[1];
      const decodedPayload = JSON.parse(atob(payloadBase64));
      const expirationTime = decodedPayload.exp * 1000;
      const currentTime = Date.now();
      return expirationTime > currentTime;
    }
  }, []);

  // Click handler for Login/Dashboard link in the header
  const handleLoginOrDashboardClick = () => {
    if (isLoggedIn) {
      window.location.href = "/Dashboard"; // Redirect to Dashboard if logged in
    } else {
      window.location.href = "/Login"; // Redirect to Login if not logged in
    }
  };

  // Render the Header component with props for login state and click handler
  return (
    <Header
      isLoggedIn={isLoggedIn}
      onLoginOrDashboardClick={handleLoginOrDashboardClick}
    />
  );
};

export default App;
