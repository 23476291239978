import React from "react";
import "../stylings/TermsAndConditions.css"; // Ensure you have the corresponding CSS file for styling

const TermsAndConditions = () => {
  return (
    <div className="terms-page">
      <header className="terms-header">
        <h1>Terms and Conditions</h1>
        <p>Last updated: October 6, 2024</p>
      </header>

      <section className="terms-content">
        {/* Table of Contents */}
        <h2>Table of Contents</h2>
        <ul className="terms-toc">
            <li><a href="#introduction">1. Introduction</a></li>
            <li><a href="#service-description">2. Service Description</a></li>
            <li><a href="#data-collection">3. Data Collection and Storage</a></li>
            <li><a href="#data-retention">4. Data Retention Period</a></li>
            <li><a href="#payment-and-refunds">5. Payment and Refunds</a></li>
            <li><a href="#no-guarantee">6. No Guarantee of Payout</a></li>
            <li><a href="#security">7. Security</a></li>
            <li><a href="#user-responsibilities">8. User Responsibilities</a></li>
            <li><a href="#changes-to-terms">9. Changes to Terms</a></li>
            <li><a href="#limitation-of-liability">10. Limitation of Liability</a></li>
            <li><a href="#contact">11. Contact Us</a></li>
        </ul>

        <h2 id="introduction">1. Introduction</h2>
        <p>
          Welcome to Crazy Claims! By accessing and using our services, you agree to be bound by these terms and conditions. Please read them carefully before using our services. If you do not agree with these terms, please discontinue using our services.
        </p>

        <h2 id="service-description">2. Service Description</h2>
        <p>
          Crazy Claims provides a service to assist with filing bond claims for unpaid invoices. Our flat service fee is <strong>$250</strong> per claim, covering the administrative, documentation, and processing work required to submit your claim. Please note that this fee is non-refundable and does not guarantee a payout. By using our service, you acknowledge and agree to these terms.
        </p>

        <h2 id="data-collection">3. Data Collection and Storage</h2>
        <p>
          We collect and store the following information necessary to process and file your claim:
        </p>
        <ul style={{ paddingLeft: "20px" }}>
          <li>Full Name</li>
          <li>Address</li>
          <li>Email</li>
          <li>Phone Number</li>
          <li>Claim Details</li>
          <li>Uploaded Files (related to your claim)</li>
        </ul>
        <p>
          <strong>Important:</strong> We do not store your credit card information, as payments are securely processed through Stripe and PayPal. Additionally, any driver’s license information provided will be deleted <strong>30 days</strong> after submission through an automated deletion process managed by our event scheduler, ensuring your privacy and data security.
        </p>

        <h2 id="data-retention">4. Data Retention Period</h2>
        <p>
          Essential claim data, such as claim details, contact information, and uploaded files, are retained for a period of <strong>7 years</strong> to comply with industry standards, enable support, and facilitate auditing if needed. All non-essential data is securely deleted using automated database cleanup scripts and secure deletion protocols. After the retention period, we will permanently delete any remaining claim data using industry-standard security measures.
        </p>

        <h2 id="payment-and-refunds">5. Payment and Refunds</h2>
        <p>
          The <strong>$250</strong> service fee is non-refundable and covers all administrative, documentation, and processing tasks related to filing your claim. Payments are processed through Stripe or PayPal, and we do not store any payment information on our servers. By proceeding with payment, you accept that this fee is non-refundable, regardless of the outcome of your claim.
        </p>

        <h2 id="no-guarantee">6. No Guarantee of Payout</h2>
        <p>
          Crazy Claims serves as a facilitator to ensure that your claim is filed correctly and with the required documentation. However, we do not guarantee a payout. The outcome of your claim depends on the final decision made by the bondsman and other parties involved, which are outside of our control. We will keep you informed of any updates and provide contact information if necessary for further follow-up.
        </p>

        <h2 id="security">7. Security</h2>
        <p>
          We use Amazon Web Services (AWS) for backend hosting, data storage, and management. AWS is known for its secure infrastructure and high compliance standards. To further protect your data, we employ industry best practices such as encryption, regular security checks, and strict access controls to ensure your information remains secure and private.
        </p>

        <h2 id="user-responsibilities">8. User Responsibilities</h2>
        <p>
          As a user of Crazy Claims, you agree to provide accurate, truthful, and complete information in all claim submissions. You are responsible for ensuring that all submitted documents are genuine and accurately represent your claim. Providing false or incomplete information may result in claim rejection.
        </p>

        <h2 id="changes-to-terms">9. Changes to Terms</h2>
        <p>
          Crazy Claims reserves the right to update or modify these terms and conditions at any time. Changes are effective immediately upon posting to this page, and it is your responsibility to review these terms periodically to stay informed of any modifications.
        </p>

        <h2 id="limitation-of-liability">10. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by applicable law, Crazy Claims, its officers, directors, employees, agents, and affiliates shall not be liable for any indirect, incidental, special, consequential, punitive, or exemplary damages arising out of or in connection with the use or inability to use our services. This includes, but is not limited to, damages for loss of profits, data, business, goodwill, reputation, or other intangible losses, whether or not Crazy Claims has been advised of the possibility of such damages.
        </p>
        <p>
          In no event shall Crazy Claims’ aggregate liability, whether in contract, warranty, tort (including negligence), or otherwise, exceed the amount you paid to Crazy Claims for the specific claim filing service associated with the liability. If you are dissatisfied with any part of our service or these terms, your sole and exclusive remedy is to discontinue using our service.
        </p>
        <p>
          Crazy Claims does not assume responsibility for errors, omissions, interruptions, deletions, defects, delays in operation or transmission, communications line failure, theft or destruction of, unauthorized access to, alteration of, or use of user content or data, or any breach of data security unless otherwise required by law. Additionally, Crazy Claims shall not be liable for any failure to comply with these terms where such failure is due to circumstances beyond our reasonable control, including but not limited to acts of nature, government action, labor conditions, pandemics, natural disasters, or interruptions in internet service or infrastructure.
        </p>
        <p>
          Some jurisdictions do not allow limitations on implied warranties or the exclusion or limitation of certain damages. If these laws apply to you, some or all of the above disclaimers, exclusions, or limitations may not apply to you, and you may have additional rights. In such cases, our liability will be limited to the fullest extent permitted by applicable law.
        </p>
        <p>
          By using our service, you acknowledge that you have read, understood, and agree to this Limitation of Liability clause. You further agree that this limitation of liability is an agreed allocation of risk between you and Crazy Claims, forms part of the consideration for using our service, and applies even if this limited remedy fails of its essential purpose.
        </p>


        <h2 id="contact">11. Contact Us</h2>
        <p>
          For any questions or concerns regarding these terms, please contact us via:
        </p>
        <ul style={{ paddingLeft: "20px" }}>
          <li>Email: <a href="mailto:support@crazyclaims.com">support@crazyclaims.com</a></li>
          <li>Alternative Email: <a href="mailto:contact@crazyclaims.com">contact@crazyclaims.com</a></li>
          <li>Main Phone: 773-759-1948</li>
          <li>Alternate Phone: 708-712-9510</li>
        </ul>
        <p>
          By using our service, you acknowledge that you have read, understood, and agree to these terms and conditions.
        </p>
      </section>

      <footer className="terms-footer">
        <p>&copy; 2024 Crazy Claims. All Rights Reserved.</p>
        <p>
          <strong>Privacy Policy:</strong> We prioritize your privacy and adhere to strict data handling and security practices. For more information, please review our <a href="/privacy-policy">Privacy Policy</a>.
        </p>
      </footer>
    </div>
  );
};

export default TermsAndConditions;
