import React, { useEffect, useState } from "react";
import "../stylings/Home.css";
import Header from "./Header"; // Import the new Header component
import heroImage5 from "../imgs/heroSectionIMG5.png";
import aboutUs_IMG from "../imgs/aboutUs_IMG.png";
import Loading from "./Loading"; // Import the Loading component
import { useNavigate } from "react-router-dom";

// Helper function to check if the JWT token is valid
function isTokenValid(jwtToken) {
  if (!jwtToken) return false;

  const payloadBase64 = jwtToken.split('.')[1];
  const decodedPayload = JSON.parse(atob(payloadBase64));

  // Check if the token has expired
  const expirationTime = decodedPayload.exp * 1000;
  const currentTime = Date.now();

  return expirationTime > currentTime; // Returns true if token is still valid
}

function Home() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      const jwtToken = sessionStorage.getItem("jwtToken");
      console.log("JWT Token:", jwtToken);

      if (isTokenValid(jwtToken)) {
        setIsLoggedIn(true);
        console.log("User is logged in");
      } else {
        sessionStorage.removeItem("jwtToken");
        setIsLoggedIn(false);
        console.log("User is not logged in or token is invalid");
      }

      setLoading(false);
    }, 1000);

    const handleSmoothScroll = (e) => {
      e.preventDefault();
      const targetId = e.currentTarget.getAttribute("href").substring(1);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - 10,
          behavior: "smooth",
        });
      }
    };

    const anchors = document.querySelectorAll('a[href^="#"]');
    anchors.forEach((anchor) => {
      anchor.addEventListener("click", handleSmoothScroll);
    });

    return () => {
      anchors.forEach((anchor) => {
        anchor.removeEventListener("click", handleSmoothScroll);
      });
    };
  }, []);

  const handleLoginOrDashboardClick = () => {
    if (isLoggedIn) {
      navigate("/Dashboard");
    } else {
      navigate("/Login");
    }
  };

  const handleFileClaimClick = () => {
    if (!isLoggedIn) {
      navigate("/Login", { state: { showAlert: true } });
    } else {
      navigate("/Dashboard", { state: { openClaimPopup: true } });
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      {/* Use the new Header component with the required props */}
      <section id="hero" className="hero">
        <div className="hero-top">
          <div className="hero-text-box">
            <h2>File a Claim Today for Only $250</h2>
            <p>
              Don’t let unpaid invoices go unresolved. File your claim now by uploading a bill of lading, original invoice, and rate confirmation to reclaim what’s rightfully yours. Time is money—act today!
            </p>
            <div className="hero-buttons">
              <button className="btn-primary" onClick={handleFileClaimClick}>
                File Claim
              </button>
              <button
                className="btn-secondary"
                onClick={() => (window.location.href = "/faq#service-info")}
              >
                Learn More
              </button>
            </div>
          </div>
          <img
            src={heroImage5}
            alt="Trucker Filing a Claim and Making Money"
            className="hero-img"
          />
        </div>
        <div className="hero-bottom">
          <p>"Don't leave money on the table. Claim what's yours today."</p>
        </div>
      </section>


      <section id="services" className="services">
        <div className="container">
          <h2>Our Services</h2>
          <div className="service-cards">
            <div className="card">
              <h3>File a Claim</h3>
              <p>
                For just $250, you can have your claim expertly filed by
                professionals who understand the intricacies of the system.
                Reclaim what's yours without the hassle.
              </p>
              <button
                className="btn-learn-more"
                onClick={() => navigate("/faq#file-claim-info")}
              >
                Learn More
              </button>
            </div>
            <div className="card">
              <h3>Claim Assistance</h3>
              <p>
                Navigate the claim process with ease. Our experts provide
                step-by-step guidance to ensure your claim is processed
                efficiently, leaving no stone unturned.
              </p>
              <button
                className="btn-learn-more"
                onClick={() => navigate("/faq#claim-assistance-info")}
              >
                Learn More
              </button>
            </div>
            <div className="card">
              <h3>24/7 Support</h3>
              <p>
                Questions? Issues? Our dedicated support team is available
                around the clock to assist you. We're here to ensure your
                experience is seamless.
              </p>
              <button
                className="btn-learn-more"
                onClick={() => navigate("/faq#support-info")}
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
      </section>

      <section id="pricing" className="pricing">
        <div className="container">
          <h2>Pricing</h2>
          <div className="pricing-card">
            <h3>Simple & Transparent</h3>
            <p>
              At Crazy Claims, we believe in straightforward pricing. No hidden
              fees, no complicated calculations—just a flat fee of $250 for each
              claim filed. We’re here to help you get what you deserve without
              any extra hassle.
            </p>
            <div className="price-amount">$250</div>
            <button className="btn-file-claim" onClick={handleFileClaimClick}>
              File Claim
            </button>
          </div>
        </div>
      </section>

      <section id="about" className="about">
        <div className="container">
          <h2>About Us</h2>
          <div className="about-content">
            <div className="about-image">
              <img src={aboutUs_IMG} alt="About Crazy Claims" className="about-img" />
            </div>
            <div className="about-text">
              <p>
                At Crazy Claims, we strive to make the claims process easy and accessible for individuals and businesses struggling with unpaid invoices. With years of experience, our dedicated claims team works tirelessly to help you recover the money you deserve. We’re here to support you every step of the way, taking a small fee with the aim of helping you reclaim what’s rightfully yours. If you’ve been waiting months for payment, let us fight for your right to be paid.
              </p>
              <p className="disclaimer">
                <small>
                  Disclaimer: Crazy Claims does not guarantee that you will receive a payout from your claim. While we ensure that your claim is properly filed and that all necessary steps are taken to pursue the payout, the final outcome depends on the specific circumstances of each claim and decisions made by the relevant parties. Our role is to facilitate the process, not to determine the outcome.
                </small>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="contact">
        <div className="container">
          <h2>Contact Us</h2>
          <p>
            If you have any questions or need further information, don't
            hesitate to reach out to us.
          </p>
          <div className="contact-content">
            <form className="contact-form">
              <input type="text" name="name" placeholder="Your Name" required />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                required
              />
              <textarea
                name="message"
                rows="5"
                placeholder="Your Message"
                required
              ></textarea>
              <button type="submit" className="btn-primary">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
