// src/components/CheckoutForm.jsx

import React, { useState, useEffect } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import "../stylings/Dashboard.css";
import { useNavigate } from "react-router-dom";

const CheckoutForm = ({ clientSecret, claimData, onSuccess, jwtToken, closeClaimPopup, refreshDashboard }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clientSecretState, setClientSecretState] = useState(clientSecret);

  useEffect(() => {
    // Initialize Stripe PaymentIntent
    const createPaymentIntent = async () => {
      try {
        const response = await fetch(
          "https://p2mdbvok47.execute-api.us-east-1.amazonaws.com/dev/create-payment-intent",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              amount: 25000, // $250.00 in cents
              currency: "usd",
              customerDetails: {
                first_name: claimData.first_name,
                last_name: claimData.last_name,
                email: claimData.email,
                phone_number: claimData.phone_number,
              },
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setClientSecretState(data.clientSecret);
      } catch (error) {
        console.error("Error creating PaymentIntent:", error);
        setErrorMessage("Failed to create payment. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    if (claimData) {
      createPaymentIntent();
    }
  }, [claimData]);

  const handleStripeSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage(null);

    if (!stripe || !elements) {
      setErrorMessage("Stripe is not properly loaded. Please try again.");
      setIsLoading(false);
      return;
    }

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin.replace(/^http:/, 'https:')}/payment-success`,
        },
        redirect: "if_required",
      });

      if (error) {
        setErrorMessage(error.message);
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        await fileClaimAfterPayment(paymentIntent.id);
      } else {
        setErrorMessage("Payment processing failed. Please try again.");
      }
    } catch (error) {
      console.error("Payment confirmation error:", error);
      setErrorMessage("An unexpected error occurred during payment processing.");
    } finally {
      setIsLoading(false);
    }
  };

  const fileClaimAfterPayment = async (paymentIntentId) => {
    try {
      const claimResponse = await fetch(
        "https://kbblwzpjj2.execute-api.us-east-1.amazonaws.com/dev/file-claim",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ ...claimData, og_claim_num: claimData.og_claim_num || null, paymentIntentId }),
        }
      );

      if (!claimResponse.ok) {
        const errorData = await claimResponse.json();
        throw new Error(errorData.message || "Failed to create the claim.");
      }

      const { claim_id } = await claimResponse.json();
      console.log(`Claim created with ID: ${claim_id}`);

      onSuccess();
      closeClaimPopup();
      refreshDashboard();
      navigate("/Dashboard"); // Redirect to dashboard on success
    } catch (error) {
      console.error("Error filing the claim after payment:", error);
      setErrorMessage("There was an error completing your claim. Please try again.");
    }
  };

  return (
    <div className="checkout-container">
      <form className="payment-form" onSubmit={handleStripeSubmit}>
        <h3>Pay with Card</h3>
        <PaymentElement id="payment-element" className="payment-element" />
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <button
          type="submit"
          className="btn-submit-payment"
          disabled={!stripe || isLoading}
        >
          {isLoading ? "Processing..." : "Pay $250 with Card"}
        </button>
      </form>
    </div>
  );
};

export default CheckoutForm;
