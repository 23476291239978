import React, { useState, useEffect } from "react";
import { Amplify } from "aws-amplify";
import {
  signIn,
  signOut,
  signUp,
  confirmSignUp,
  confirmSignIn,
  resetPassword,
  confirmResetPassword,
  fetchAuthSession,
} from "@aws-amplify/auth";
import {
  CognitoIdentityProviderClient,
  AdminAddUserToGroupCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../logos/crazyClaims_Rough_Logo_1.png";
import accountIcon from "../icons/crazyClaims_Account_Icon.png";
import "../stylings/Login.css";
import awsmobile from "../aws-exports";

Amplify.configure(awsmobile);

const cognitoClient = new CognitoIdentityProviderClient({
  region: awsmobile.aws_project_region,
});

const Login = () => {
  const [isCreateAccountModalOpen, setCreateAccountModalOpen] = useState(false);
  const [isForgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [isConfirmationRequired, setIsConfirmationRequired] = useState(false);
  const [isResetPasswordStep, setIsResetPasswordStep] = useState(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [username, setUsername] = useState("");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [tempUsername, setTempUsername] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.showAlert) {
      setShowAlert(true);
    }
  }, [location.state]);

  const resetFormFields = () => {
    setUsername("");
    setPassword("");
    setFirstName("");
    setLastName("");
    setDob("");
    setConfirmationCode("");
    setIsConfirmationRequired(false);
    setIsResetPasswordStep(false);
    setNewPassword("");
    setForgotPasswordEmail("");
  };

  const closeModal = () => {
    setCreateAccountModalOpen(false);
    setForgotPasswordModalOpen(false);
    resetFormFields();
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!username.trim() || !password) {
      alert("Username and Password are required");
      return;
    }

    try {
      const result = await signIn({ username: username.trim(), password });

      if (
        result.nextStep?.signInStep ===
        "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
      ) {
        setNewPasswordRequired(true);
      } else if (result.isSignedIn) {
        await handlePostSignIn();
      }
    } catch (error) {
      console.error("Error signing in:", error);
      alert(`Login failed: ${error.message || "Unknown error"}`);
    }
  };

  const handleNewPasswordSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await confirmSignIn({
        user: username.trim(),
        challengeResponse: newPassword,
      });

      if (result.isSignedIn) {
        await handlePostSignIn();
      }
    } catch (error) {
      console.error("Error completing new password:", error);
      alert(
        `Error completing new password: ${error.message || "Unknown error"}`
      );
    }
  };

  const handlePostSignIn = async () => {
    try {
      const authSession = await fetchAuthSession();
      const { accessToken } = authSession.tokens ?? {};
      sessionStorage.setItem("jwtToken", accessToken);

      const userRole = authSession.tokens.accessToken.payload["cognito:groups"];
      sessionStorage.setItem("userRole", userRole);

      const userId = await fetchUserId(username.trim());
      sessionStorage.setItem("user_id", userId);

      alert("Login successful!");

      if (userRole.includes("customer")) {
        navigate("/Dashboard");
      } else if (userRole.includes("admin")) {
        navigate("/FilerDashboard");
      }
    } catch (error) {
      console.error("Error during post sign-in:", error);
      alert("Login process encountered an error.");
    }
  };

  const fetchUserId = async (email) => {
    try {
      const response = await fetch(
        "https://yezvfsfwzg.execute-api.us-east-1.amazonaws.com/get-user-id",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
          body: JSON.stringify({ username: email }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch user ID. Status: ${response.status}`);
      }

      const data = await response.json();
      const { user_id } = data;

      if (!user_id) {
        throw new Error("User ID is missing in the response.");
      }

      console.log("User ID fetched successfully:", user_id);
      return user_id;
    } catch (error) {
      console.error("Error fetching user ID:", error);
      throw error;
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (
      !username.trim() ||
      !password ||
      !firstName.trim() ||
      !lastName.trim() ||
      !dob
    ) {
      alert("All fields are required");
      return;
    }

    try {
      await signUp({
        username: username.trim(),
        password,
        attributes: {
          email: username.trim(),
          "custom:first_name": firstName,
          "custom:last_name": lastName,
          birthdate: dob,
        },
      });

      setTempUsername(username.trim());
      setIsConfirmationRequired(true);
      alert("Verification email sent! Please enter the code.");
    } catch (error) {
      console.error("Error signing up:", error);
      resetFormFields();
      alert(`Sign up failed: ${error.message || "Unknown error"}`);
    }
  };

  const handleConfirmSignUp = async (e) => {
    e.preventDefault();

    if (!tempUsername) {
      alert("Email (Username) is required to confirm sign-up.");
      return;
    }

    try {
      const { isSignUpComplete } = await confirmSignUp({
        username: tempUsername,
        confirmationCode,
      });

      if (isSignUpComplete) {
        alert("Email confirmed! Logging in...");

        const result = await signIn({ username: tempUsername, password });
        if (result.isSignedIn) {
          await addUserToCustomerGroup(tempUsername);
          await signOut();
          await signInWithUpdatedTokens(tempUsername, password);
          await fetchUserSessionWithRetries();
          await createUserInDB({
            first_name: firstName,
            last_name: lastName,
            dob: dob,
            email: tempUsername,
          });
        }
      }
    } catch (error) {
      console.error("Error confirming sign-up:", error);
      alert(`Confirmation failed: ${error.message || "Unknown error"}`);
    }
  };

  const signInWithUpdatedTokens = async (username, password) => {
    try {
      const result = await signIn({ username, password });
      if (result.isSignedIn) {
        console.log("Tokens refreshed successfully.");
      }
    } catch (error) {
      console.error("Error signing in again:", error);
    }
  };

  const addUserToCustomerGroup = async (username) => {
    const authSession = await fetchAuthSession();
    const credentials = authSession.credentials;
    const client = new CognitoIdentityProviderClient({
      credentials: credentials,
      region: awsmobile.aws_project_region,
    });
    const command = new AdminAddUserToGroupCommand({
      UserPoolId: awsmobile.aws_user_pools_id,
      Username: username,
      GroupName: "customer",
    });

    try {
      await client.send(command);
      console.log(`User ${username} added to customer group`);
    } catch (error) {
      console.error("Error adding user to group:", error);
    }
  };

  const fetchUserSessionWithRetries = async () => {
    let retries = 3;
    let authSession = null;
    while (retries > 0) {
      try {
        authSession = await fetchAuthSession();
        const { accessToken } = authSession.tokens ?? {};
        sessionStorage.setItem("jwtToken", accessToken);

        const userRole =
          authSession.tokens.accessToken.payload["cognito:groups"];
        sessionStorage.setItem("userRole", userRole);

        if (userRole && userRole.length > 0) {
          alert("Login successful!");

          if (userRole.includes("customer")) {
            navigate("/Dashboard");
          } else if (userRole.includes("admin")) {
            navigate("/FilerDashboard");
          }
          return;
        } else {
          throw new Error("User role not available yet.");
        }
      } catch (error) {
        console.log(`Retrying session fetch (${3 - retries + 1}/3)...`);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        retries--;
      }
    }
    console.error("Failed to fetch user session with role information.");
  };

  const createUserInDB = async (userData) => {
    try {
      const authSession = await fetchAuthSession();
      const { accessToken } = authSession.tokens ?? {};
      sessionStorage.setItem("jwtToken", accessToken);

      const response = await fetch(
        "https://yezvfsfwzg.execute-api.us-east-1.amazonaws.com/create-user",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
          body: JSON.stringify(userData),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to create user in DB. Status: ${response.status}`
        );
      }

      const data = await response.json();
      console.log("User created in DB:", data);
    } catch (error) {
      console.error("Error creating user in DB:", error);
      alert(`Failed to create user record in database: ${error.message}`);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    if (!forgotPasswordEmail.trim()) {
      alert("Email is required to reset the password.");
      return;
    }

    try {
      const output = await resetPassword({
        username: forgotPasswordEmail.trim(),
      });
      handleResetPasswordNextSteps(output);
    } catch (error) {
      console.error("Error resetting password:", error);
      alert(`Reset failed: ${error.message || "Unknown error"}`);
    }
  };

  const handleResetPasswordNextSteps = (output) => {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        alert("A confirmation code was sent to your email");
        setIsResetPasswordStep(true);
        break;
      case "DONE":
        alert("Successfully reset password.");
        break;
      default:
        alert("Unknown reset password step.");
    }
  };

  const handleConfirmResetPassword = async (e) => {
    e.preventDefault();

    try {
      await confirmResetPassword({
        username: forgotPasswordEmail.trim(),
        confirmationCode,
        newPassword,
      });

      alert(
        "Password reset successfully! You can now log in with the new password."
      );
      setIsResetPasswordStep(false);
      closeModal();
    } catch (error) {
      console.error("Error confirming reset password:", error);
      alert(`Reset confirmation failed: ${error.message || "Unknown error"}`);
    }
  };

  const openCreateAccountModal = () => {
    resetFormFields();
    setCreateAccountModalOpen(true);
  };

  return (
    <div className="login-page">
      <section id="login" className="login-section">
        <div className="container">
          {showAlert && (
            <div className="alert">You must log in to file a claim.</div>
          )}
          <div className="login-card">
            <h2>Login</h2>
            {newPasswordRequired ? (
              <form onSubmit={handleNewPasswordSubmit}>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Enter new password"
                  required
                />
                <button type="submit" className="btn-login">
                  Submit New Password
                </button>
              </form>
            ) : (
              <form onSubmit={handleLogin} className="login-form">
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value.trim())}
                  placeholder="Email"
                  required
                />
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                />
                <button type="submit" className="btn-login">
                  Login
                </button>
              </form>
            )}
            <div className="login-options">
              <button
                type="button"
                className="link-button forgot-password"
                onClick={() => setForgotPasswordModalOpen(true)}
              >
                Forgot your password?
              </button>
              <button
                type="button"
                className="link-button create-account"
                onClick={openCreateAccountModal}
              >
                Create an account
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Create Account Modal */}
      {isCreateAccountModalOpen && (
        <div className="modal active" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <h2>Create an Account</h2>
            {!isConfirmationRequired ? (
              <form className="create-account-form" onSubmit={handleSignUp}>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                  required
                />
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                  required
                />
                <input
                  type="date"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  placeholder="Date of Birth"
                  required
                />
                <input
                  type="email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Email"
                  required
                />
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                />
                <button type="submit" className="btn-create-account">
                  Sign Up
                </button>
              </form>
            ) : (
              <form onSubmit={handleConfirmSignUp}>
                <input
                  type="text"
                  value={confirmationCode}
                  onChange={(e) => setConfirmationCode(e.target.value)}
                  placeholder="Confirmation Code"
                  required
                  className="confirmation-code-input"
                />
                <button
                  type="submit"
                  className="btn-create-account btn-confirm-code"
                >
                  Confirm Sign Up
                </button>
              </form>
            )}
          </div>
        </div>
      )}

      {/* Forgot Password Modal */}
      {isForgotPasswordModalOpen && (
        <div className="modal forgot-password-modal active" onClick={closeModal}>
          <div
            className="modal-content forgot-password-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close forgot-password-close" onClick={closeModal}>
              &times;
            </span>
            <h2>Forgot Your Password?</h2>
            {!isResetPasswordStep ? (
              <form className="forgot-password-form" onSubmit={handleForgotPassword}>
                <input
                  type="email"
                  value={forgotPasswordEmail}
                  onChange={(e) => setForgotPasswordEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                />
                <button type="submit" className="btn-forgot-password">
                  Send Reset Code
                </button>
              </form>
            ) : (
              <form className="reset-password-form" onSubmit={handleConfirmResetPassword}>
                <input
                  type="text"
                  value={confirmationCode}
                  onChange={(e) => setConfirmationCode(e.target.value)}
                  placeholder="Enter confirmation code"
                  required
                />
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="New Password"
                  required
                />
                <button type="submit" className="btn-reset-password">
                  Reset Password
                </button>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
