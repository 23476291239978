import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { fetchAuthSession, getCurrentUser, signOut } from "@aws-amplify/auth";

const ProtectedRoute = ({ children, requiredRole }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null = loading, true = authenticated, false = not authenticated
  const [userRole, setUserRole] = useState(null); // User role
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const checkAuth = async () => {
      try {
        // Check if user is authenticated
        await getCurrentUser(); // This will throw an error if the user is not authenticated
        const authSession = await fetchAuthSession();

        // Get the user role from the ID token in the session
        const { idToken } = authSession.tokens ?? {};
        if (idToken) {
          console.log("ID Token found:", idToken);

          // Set user role based on the 'cognito:groups' attribute
          const { "cognito:groups": roles = [] } = idToken.payload;
          setUserRole(roles.includes("admin") ? "admin" : "customer");
          setIsAuthenticated(true); // User is authenticated
        }
      } catch (error) {
        console.error("Authentication check failed:", error);
        setIsAuthenticated(false); // User is not authenticated
        sessionStorage.clear(); // Clear session storage upon failure
      } finally {
        setLoading(false); // Finish loading
      }
    };

    checkAuth();
  }, []);

  // If authenticated, check if the user has the correct role
  useEffect(() => {
    const handleUnauthorizedUser = async () => {
      if (isAuthenticated && userRole && userRole !== requiredRole) {
        console.log(
          "User role does not match the required role. Signing out..."
        );

        try {
          // Sign out the user if they don't have the required role
          await signOut();
          sessionStorage.clear(); // Clear session storage after sign-out
          console.log("User signed out due to unauthorized role.");
        } catch (error) {
          console.error("Error during sign-out due to role mismatch:", error);
        } finally {
          setIsAuthenticated(false); // User is no longer authenticated
        }
      }
    };

    handleUnauthorizedUser();
  }, [isAuthenticated, userRole, requiredRole]);

  // Show a loading screen while authentication is being checked
  if (loading || isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  // If not authenticated, redirect to the login page
  if (!isAuthenticated) {
    return <Navigate to="/Login" replace />;
  }

  // If the user role matches the required role, render the protected component
  if (userRole === requiredRole) {
    return children;
  }

  // If the user is authenticated but doesn't have the required role, redirect them to home
  return <Navigate to="/" replace />;
};

export default ProtectedRoute;
