import React, { useState } from "react";
import logo from "../logos/crazyClaims_Rough_Logo_1.png";
import accountIcon from "../icons/crazyClaims_Account_Icon.png";
import "../stylings/CustomerSupport.css"; // Make sure the styles are in this file or modify accordingly

const CustomerSupport = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    claimNumber: "",
    message: "",
    severity: "low",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic, e.g., send the data to the server or show a success message
    console.log(formData);
  };

  return (
    <div className="customer-support">

      <section id="support" className="support-section">
        <div className="container">
          <h2>Contact Our Support Team</h2>
          <p>
            We’re here 24/7 to assist you with any questions or issues you may
            have.
          </p>
          <form onSubmit={handleSubmit} className="support-form">
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="claimNumber">Claim Filing Number</label>
              <input
                type="text"
                id="claimNumber"
                name="claimNumber"
                value={formData.claimNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="form-group">
              <label htmlFor="severity">Severity Level</label>
              <select
                id="severity"
                name="severity"
                value={formData.severity}
                onChange={handleChange}
                required
              >
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
                <option value="urgent">Urgent</option>
              </select>
            </div>
            <button type="submit" className="btn-primary">
              Submit
            </button>
          </form>
        </div>
      </section>

      <footer className="footer">
        <div className="container">
          <div className="footer-content">
            <p>&copy; 2024 Crazy Claims. All Rights Reserved.</p>
            <p>
              <strong>Contact Us:</strong>{" "}
              <a href="mailto:support@crazyclaims.com">
                support@crazyclaims.com
              </a>{" "}
              | Phone: (555) 123-4567
            </p>
            <p>
              <strong>Location:</strong> 123 Freight Drive, Logistics City, IL
              60000
            </p>
            <p>
              <strong>Follow Us:</strong>
              <a href="#" className="footer-social-link">
                Twitter
              </a>{" "}
              |
              <a href="#" className="footer-social-link">
                Facebook
              </a>{" "}
              |
              <a href="#" className="footer-social-link">
                LinkedIn
              </a>
            </p>
            <p className="disclaimer">
              <small>
                Disclaimer: Crazy Claims does not guarantee that you will
                receive a payout from your claim. We ensure that the claim is
                properly filed and all necessary steps are taken to pursue the
                payout from the bondsman.
              </small>
            </p>
            <hr />
            <p>
              <small>
                Website developed by{" "}
                <a href="https://theradicalsoftware.com" target="_blank">
                  Radical Software
                </a>
              </small>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default CustomerSupport;
